import React, { useCallback } from "react";
import { Box, InputAdornment, TextField, Typography } from "@material-ui/core";
import NumberFormat from "react-number-format";
import cx from "classnames";

import SliderInput from "./SliderInput";
import { useCommonStyles } from "../../../../utils/loan-application.styles";

const BaseNumberInput = ({
  label,
  optionLabel,
  value = 0,
  onChange,
  minValue = 1000,
  maxValue = 100000,
  startAdornment,
  endAdornment,
}) => {
  const commonClasses = useCommonStyles();

  const handleSliderChange = useCallback((sliderValue) => {
    onChange(sliderValue);
  }, []);

  const handleInputChange = (event) => {
    const newValue = Number(event.target.value.split(",").join(""));
    if (!isNaN(newValue)) {
      handleSliderChange(newValue);
    }
  };

  return (
    <Box display="flex" flexDirection="column" gridRowGap="16px" width="100%">
      {(label || optionLabel) && (
        <Box display="flex" justifyContent="space-between" paddingRight="16px">
          <Typography
            classes={{
              root: cx(commonClasses.fontSize14, commonClasses.fontWeightBold),
            }}
          >
            {label}
          </Typography>
          <Typography
            classes={{
              root: cx(commonClasses.fontSize14, commonClasses.fontWeightBold),
            }}
          >
            {optionLabel}
          </Typography>
        </Box>
      )}
      <Box display="flex" width="100%">
        <Box
          display="flex"
          flexDirection="column"
          gridRowGap="16px"
          flexGrow="1"
        >
          <TextField
            value={value}
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {startAdornment}
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="start">{endAdornment}</InputAdornment>
              ),
              inputComponent: NumberFormat,
              inputProps: {
                thousandSeparator: true,
                isNumericString: true,
              },
            }}
          />
          <SliderInput
            onChange={handleSliderChange}
            min={minValue}
            max={maxValue}
            value={value}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default BaseNumberInput;
